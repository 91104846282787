import React from "react";
import styles from "../css/About.module.css";

export const About = () => {
  return (
    <section id="about" className={styles.section}>
      <h1 className={styles.title}>About me</h1>
      <div className={styles.container}>
        <p>
          I am very passionate about the constantly changing world of
          technology, but what really excites me is creating things. Whether
          it’s building software or trying out new ideas, I find a lot of joy in
          bringing concepts to life through coding.<br /><br /> Programming where I do my best work, allowing me to combine logic
          with imagination to create unique solutions. I naturally like to dig
          deep into topics, trying to understand them fully. This curiosity
          drives my careful approach to solving problems, making sure I not only
          finish tasks but master them with accuracy and care. <br /><br /> I’m motivated by
          a desire to keep learning and growing, taking on new challenges and
          experiences along the way. As someone who enjoys exploring different
          areas, I see myself as a lifelong learner and someone who is skilled
          in many things. Whether it’s a new technology, a creative project, or
          a new field, I’m always excited to jump in and learn more.
        </p>
      </div>
    </section>
  );
};

export default About;
