import React, { useState, useEffect } from "react";
import styles from "./css/Navbar.module.css";
import DownloadIcon from "./svg/download";

function smoothScroll(duration, targetId) {
  const target = document.getElementById(targetId);
  const targetPosition = target.getBoundingClientRect().top - 60;
  const startPosition = window.scrollY;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, targetPosition, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const downloadResume = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/cv.pdf`; // Chemin vers ton fichier
    link.download = 'youcef_bouterfass.pdf'; // Nom du fichier lors du téléchargement
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition + 65 > window.innerHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Ajoute un écouteur d'événement pour le défilement
    window.addEventListener("scroll", handleScroll);

    // Nettoie l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Le tableau vide [] signifie que cet effet s'exécute une fois après le premier rendu

  return (
    <nav className={`${styles.bar} ${scrolled ? styles.scrolled : ""}`}>
      <ul className={styles.list}>
        <a href="home" className={styles.logo}>
          <span>YB</span>
        </a>
        <div className={styles.links}>
          <li>
            <a href="#about" onClick={() => smoothScroll(800, "about")}>
              about
            </a>
          </li>
          <li>
            <a href="#formation" onClick={() => smoothScroll(800, "formation")}>
              education
            </a>
          </li>
          <li>
            <a
              href="#competences"
              onClick={() => smoothScroll(800, "competences")}
            >
              skills
            </a>
          </li>
          <li>
            <a href="#contact" onClick={() => smoothScroll(800, "contact")}>
              contact
            </a>
          </li>
        </div>
        <div className={styles.dark}>
          {scrolled && (
            <li>
              <button
                href="#"
                className={styles.buttonresume}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={downloadResume}
              >
                <DownloadIcon isHovered={isHovered} />
                <span>my resume</span>
              </button>
            </li>
          )}
        </div>
      </ul>
    </nav>
  );
}

export default Navbar;
