
export default function FlagIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 3 2">
            <rect width="1" height="2" fill="#002395" />
            <rect x="1" width="1" height="2" fill="#fff" />
            <rect x="2" width="1" height="2" fill="#ED2939" />
        </svg>
    )
}
