import React, { useEffect, useRef } from "react";
import styles from "../css/Formation.module.css";
import TimeLine from "../TimeLine";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Formation = () => {
  const events = [
    {
      title: "Call A Lawyer (CDI)",
      date: "oct. 2023 - apr. 2024",
      description: `Development of new features and maintenance of the callawyer.fr platform:
      -Development of a marketplace
      -Creation of webhooks (Stripe, Slack)
      -Graphic redesign of the lawyer section
      -Synchronization of Webflow/MongoDB databases
      -Update of the in-house API (Python)
      
      Stack: MeteorJS, React, MongoDB
        `,
    },
    {
      title: "3WA (apprenticeship)",
      date: "oct. 2022 - oct. 2023",
      description: `An online school where I learned the basics of the Web, from front-end to back-end, including database management and a bit of security.

      Skills: JS (ES6), MVC, Node, Express, Databases`
    },
    {
      title: "42 School",
      date: "2019-2022",
      description: `Peer-to-Peer learning school, the most impactful experience in terms of autonomy, discipline, and the self-discipline required for developers.
        
      Skills: C, C++, Shell, Algorithms`,
    },
    {
      title: "The Hacking Project",
      date: "oct 2020 - dec. 2020",
      description: `Three-month training in a group of four.
      I learned teamwork, communication, task delegation, etc.

      Skills: Ruby, Ruby on Rails, HTML, CSS, JS, MVC, GIT`
    },
    {
      title: "University of Paris",
      date: "2014-2019",
      description: `Skills: Networking, Operating Systems, OOP, Databases`,
    },
    {
      title: "Scientific Baccalaureate",
      date: "2013",
      description: `Physics-Chemistry option
      `,
    },
  ];

  const timelineAreaRef = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: timelineAreaRef.current,
      start: "top 40%",
      onEnter: () => {
        timelineAreaRef.current.style.overflowY = "auto"; // Bloquer le défilement vertical
      },
      onLeaveBack: () => {
        timelineAreaRef.current.style.overflowY = "hidden"; // Réactiver le défilement vertical si vous revenez en arrière
      },
      onEnterBack: () => {
        timelineAreaRef.current.style.overflowY = "auto"; // Bloquer à nouveau en cas de retour en arrière
      },
      onLeave: () => {
        timelineAreaRef.current.style.overflowY = "hidden"; // Réactiver le défilement vertical après l'animation
      },
    });
  }, []);
  return (
    <section id="formation" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.text}>
          <div className={styles.paragraphe}>
            <h1 className={styles.title}>Education and Experience</h1>
            <p>
              My education and professional experience since obtaining my high
              school diploma.
            </p>
          </div>
        </div>
        <div ref={timelineAreaRef} className={styles["timeline-area"]}>
          <TimeLine events={events} />
        </div>
      </div>
    </section>
  );
};

export default Formation;
