import React from 'react';

const StarRating = ({ rating, maxRating = 5 }) => {
  // Créer un tableau pour les étoiles
  const stars = [];
  
  for (let i = 1; i <= maxRating; i++) {
    if (i <= rating) {
      stars.push(<span key={i} style={styles.filledStar}>★</span>); // Étoile remplie
    } else {
      stars.push(<span key={i} style={styles.emptyStar}>☆</span>); // Étoile vide
    }
  }
  
  return <div>{stars}</div>;
};

// Styles pour les étoiles
const styles = {
  filledStar: {
    color: 'gold',
    fontSize: '40px',
    marginRight: '5px',
  },
  emptyStar: {
    color: 'gold',
    fontSize: '40px',
    marginRight: '5px',
    padding: '2px',
  },
};

export default StarRating;
