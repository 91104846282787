import React from "react";
import Navbar from "./Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Competences from "./pages/Competences";
import Formation from "./pages/Formation";
import Contact from "./pages/Contact";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useIsMobile from "./hooks/useIsMobile";
import NavMob from "./NavMob";

gsap.registerPlugin(ScrollTrigger);


function App() {
  const isMobile = useIsMobile();

  return (
    <div>
      {isMobile ? <NavMob /> : <Navbar />}
      <Home />
      <About />
      <Formation />
      <Competences />
      <Contact />
    </div>
  );
}

export default App;
