import React, { useState, useEffect } from "react";
import styles from './css/NavMob.module.css';


const NavMob = () => {
    const [scrolled, setScrolled] = useState(false);
 
    const [isOpen, setIsOpen] = useState(false);



    function smoothScroll(duration, targetId) {
        const target = document.getElementById(targetId);
        const targetPosition = target.getBoundingClientRect().top - 60;
        const startPosition = window.scrollY;
        setIsOpen(false);
        let startTime = null;

        function animation(currentTime) {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const run = ease(timeElapsed, startPosition, targetPosition, duration);
            window.scrollTo(0, run);
            if (timeElapsed < duration) requestAnimationFrame(animation);
        }

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        }

        requestAnimationFrame(animation);
    }

    const downloadResume = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/cv.pdf`;
        link.download = 'youcef_bouterfass.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition =
                window.scrollY || document.documentElement.scrollTop;

            if (scrollPosition + 65 > window.innerHeight) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        console.log(isOpen);

        setIsOpen(!isOpen);
    }
    console.log('isopen', isOpen);

    return (
        <nav className={`${styles.bar} ${isOpen ? styles['bar-open'] : styles['bar-close']}`}>
            <div className={styles.icons}>
                <div>
                    <span style={{ fontWeight: "bold" }}>YB</span>
                </div>
                <div onClick={toggleMenu} className={styles.hamburger}>
                    {isOpen ? 'X' : '☰'}
                </div>
            </div>
            {isOpen &&
                <ul className={styles.list}>
                    <li>
                        <a href="#about" onClick={() => smoothScroll(800, "about")}>
                            about
                        </a>
                    </li>
                    <li>
                        <a href="#formation" onClick={() => smoothScroll(800, "formation")}>
                            education
                        </a>
                    </li>
                    <li>
                        <a
                            href="#competences"
                            onClick={() => smoothScroll(800, "competences")}
                        >
                            skills
                        </a>
                    </li>
                    <li>
                        <a href="#contact" onClick={() => smoothScroll(800, "contact")}>
                            contact
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className={styles.buttonresume}
                            onClick={downloadResume}
                        >
                            <span>my resume</span>
                        </a>
                    </li>
                </ul>
            }
        </nav>
    );
}

export default NavMob;