import React, { useEffect, useRef } from "react";
import styles from "../css/Home.module.css";
import gsap from "gsap";
import useIsMobile from "../hooks/useIsMobile";
import FlagIcon from "../svg/FlagIcon";


const Home = () => {
  const boxRef = useRef(null);
  const isMobile = useIsMobile();
  const downloadResume = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/cv.pdf`; // Chemin vers ton fichier
    link.download = "youcef_bouterfass.pdf"; // Nom du fichier lors du téléchargement
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const box = boxRef.current;

    if (!isMobile) {

      gsap.fromTo(
        box,
        { opacity: 1, y: -135 },
        {
          opacity: 0,
          y: 25,
          duration: 0.6,
          scrollTrigger: {
            trigger: box,
            start: isMobile ? 'top 30%' : 'top 30%', // Démarre l'animation lorsque le haut de l'élément atteint 80% de la hauteur de la fenêtre
            toggleActions: "play none none reverse",
          },
        }
      );
    }
  }, []);

  return (
    <section id="home" className={styles["video-section"]}>
      {!isMobile ? (<>
        <video autoPlay muted loop id="background-video">
          <source src="/scenery.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </>) : (<div className={styles['bg-pic']}>
      </div>)
      }
      <div className={styles["overlay"]}></div>
      <div ref={boxRef} className={styles["content"]}>
        <h1 className={styles["first-name"]}>Youcef</h1>
        <h1 className={styles["last-name"]}>Bouterfass</h1>
        <p className={styles["phrase"]}>Junior developer based in Paris <FlagIcon />.</p>
        <button onClick={downloadResume} className={styles.download}>
          download resume
        </button>
      </div>
    </section>
  );
};

export default Home;
