import React, { useRef } from "react";
import emailjs from "emailjs-com";
import styles from "../css/Contact.module.css";

function Contact() {
  const form = useRef();

  const emailjsApiKey = process.env.REACT_APP_EMAILJS_API_KEY;
  const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        emailjsServiceId,
        emailjsTemplateId,
        form.current,
        emailjsApiKey
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message envoyé avec succès !");
        },
        (error) => {
          console.log(error.text);
          alert("Une erreur s'est produite, veuillez réessayer.");
        }
      );
  };

  return (
    <section id="contact" className={styles.section}>
      <h1 className={styles.title}>Contact me</h1>
      <div className={styles.container}>
        <div className={styles["form-container"]}>
          <form ref={form} onSubmit={sendEmail} className={styles.form}>
            <div>
              <label>Name</label>
              <input className={styles.name} type="text" name="to_name" />
            </div>
            <div>
              <label>Email</label>
              <input className={styles.email} type="email" name="from_name" required />
            </div>
            <div>
              <label>Message</label>
              <textarea className={styles.message} name="message" required />
            </div>
            <div className={styles['send-container']}>
              <input className={styles.send} type="submit" value="Send" />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
