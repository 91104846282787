import React, { useEffect, useRef } from "react";
import styles from "./css/TimeLine.module.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useIsMobile from "./hooks/useIsMobile";

const TimeLine = ({ events }) => {
  const timelineRef = useRef(null);
  const boxRefs = useRef([]);
  const isMobile = useIsMobile();

  useEffect(() => {

    gsap.set(boxRefs.current, { opacity: 0, y: 50 });

    ScrollTrigger.create({
      trigger: timelineRef.current,
      start: "top 40%", // Déclenche l'animation lorsque le haut de la div timeline atteint 80% de la hauteur de l'écran
      onEnter: () => {
        boxRefs.current.forEach((box, index) => {
          gsap.to(box,
            {
              opacity: 1,
              y: 0,
              duration: 0.6,
              delay: index * 0.2, // Délai pour les animations séquentielles
            }
          );
        });
      },
    });
  }, []);
  return (
    <div ref={timelineRef} className={styles.timeline}>
      {events &&
        events.map((ev, index) => {
          return (
            <div
            ref={(el) => (boxRefs.current[index] = el)}
              key={index}
              className={`${styles.container} ${
                styles[index % 2 === 0 ? "left-container" : "right-container"]
              }`}
            >
              <div className={styles.circle}></div>
              <div
                className={`${styles["text-box"]} ${
                  styles[index % 2 === 0 ? "text-left" : "text-right"]
                }`}
              >
                {isMobile ? <h3>{ev.title}</h3> : <h2>{ev.title}</h2> }
                <small>{ev.date}</small>
                <p style={{ whiteSpace: 'pre-line' }}>{ev.description}</p>
                <span
                  className={`${
                    styles[
                      index % 2 === 0
                        ? "left-container-arrow"
                        : "right-container-arrow"
                    ]
                  }`}
                ></span>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TimeLine;
