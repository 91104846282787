import React, { useState } from "react";
import styles from "../css/Competences.module.css";
import CssIcon from "../svg/CssIcon";
import PythonIcon from "../svg/PythonIcon";
import JsIcon from "../svg/JsIcon";
import HtmlIcon from "../svg/HtmlIcon";
import ReactIcon from "../svg/ReactIcon";
import NodeIcon from "../svg/NodeIcon";
import IconC from "../svg/IconC";
import JavaIcon from "../svg/JavaIcon";
import RorIcon from "../svg/RorIcon";
import RubyIcon from "../svg/RubyIcon";
import SqlIcon from "../svg/SqlIcon";
import MongoIcon from "../svg/MongoIcon";
import GitIcon from "../svg/GitIcon";
import GithubIcon from "../svg/GithubIcon";
import TypeIcon from "../svg/TypeIcon";
import StarRating from "../StarRating";

import useIsMobile from "../hooks/useIsMobile";

function Competences() {
  const tech = {
    HTML: {
      title: "HTML",
      description: "blbla",
      rate: 5,
    },
    CSS: {
      title: "CSS",
      description: "blbla",
      rate: 5,
    },
    JS: {
      title: "Javascript",
      description: "blbla",
      rate: 5,
    },
    PYTHON: {
      title: "Python",
      description: "blbla",
      rate: 4,
    },
    REACT: {
      title: "ReactJS",
      description: "blbla",
      rate: 5,
    },
    C: {
      title: "C",
      description: "blbla",
      rate: 4,
    },
    NODE: {
      title: "nodeJS",
      description: "blbla",
      rate: 5,
    },
    JAVA: {
      title: "Java",
      description: "blbla",
      rate: 3,
    },
    RUBY: {
      title: "Ruby",
      description: "blbla",
      rate: 3,
    },
    ROR: {
      title: "Ruby On Rails",
      description: "blbla",
      rate: 3,
    },
    GIT: {
      title: "Git",
      description: "blbla",
      rate: 4,
    },
    GITHUB: {
      title: "Github",
      description: "blbla",
      rate: 4,
    },
    TYPE: {
      title: "Typescript",
      description: "blbla",
      rate: 1,
    },
    SQL: {
      title: "SQL",
      description: "",
      rate: 3,
    },
    MONGO: {
      title: "mongoDB",
      description: "blbla",
      rate: 4,
    },
  };
  const [hoveredSkill, setHoveredSkill] = useState("");
  const isMobile = useIsMobile();

  const handleMouseEnter = (skillName) => {
    setHoveredSkill({
      title: tech[skillName]["title"],
      description: tech[skillName]["description"],
      rate: tech[skillName].rate,
    });
  };

  const handleMouseLeave = () => {
    setHoveredSkill("");
  };

  return (
    <section id="competences" className={styles.section}>      
      <div className={styles["left-side"]}>
       {!isMobile && <div className={styles.title}>Skills </div>}
        {isMobile ? (

          <div>
            <div className={styles.pyramid}>
              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("HTML")}
                  onMouseLeave={handleMouseLeave}
                >
                  <HtmlIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("CSS")}
                  onMouseLeave={handleMouseLeave}
                >
                  <CssIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("JS")}
                  onMouseLeave={handleMouseLeave}
                >
                  <JsIcon />
                </span>



                <span
                  onMouseEnter={() => handleMouseEnter("NODE")}
                  onMouseLeave={handleMouseLeave}
                >
                  <NodeIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("REACT")}
                  onMouseLeave={handleMouseLeave}
                >
                  <ReactIcon />
                </span>
              </div>
              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("PYTHON")}
                  onMouseLeave={handleMouseLeave}
                >
                  <PythonIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("MONGO")}
                  onMouseLeave={handleMouseLeave}
                >
                  <MongoIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("GIT")}
                  onMouseLeave={handleMouseLeave}
                >
                  <GitIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("GITHUB")}
                  onMouseLeave={handleMouseLeave}
                >
                  <GithubIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("C")}
                  onMouseLeave={handleMouseLeave}
                >
                  <IconC />
                </span>
              </div>
              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("ROR")}
                  onMouseLeave={handleMouseLeave}
                >
                  <RorIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("RUBY")}
                  onMouseLeave={handleMouseLeave}
                >
                  <RubyIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("SQL")}
                  onMouseLeave={handleMouseLeave}
                >
                  <SqlIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("JAVA")}
                  onMouseLeave={handleMouseLeave}
                >
                  <JavaIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("TYPE")}
                  onMouseLeave={handleMouseLeave}
                >
                  <TypeIcon />
                </span>
              </div>
            </div>
          </div>
        ) : (

          <div>
            <div className={styles.pyramid}>
              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("HTML")}
                  onMouseLeave={handleMouseLeave}
                >
                  <HtmlIcon />
                </span>
              </div>
              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("CSS")}
                  onMouseLeave={handleMouseLeave}
                >
                  <CssIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("JS")}
                  onMouseLeave={handleMouseLeave}
                >
                  <JsIcon />
                </span>
              </div>

              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("NODE")}
                  onMouseLeave={handleMouseLeave}
                >
                  <NodeIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("REACT")}
                  onMouseLeave={handleMouseLeave}
                >
                  <ReactIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("PYTHON")}
                  onMouseLeave={handleMouseLeave}
                >
                  <PythonIcon />
                </span>
              </div>

              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("MONGO")}
                  onMouseLeave={handleMouseLeave}
                >
                  <MongoIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("GIT")}
                  onMouseLeave={handleMouseLeave}
                >
                  <GitIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("GITHUB")}
                  onMouseLeave={handleMouseLeave}
                >
                  <GithubIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("C")}
                  onMouseLeave={handleMouseLeave}
                >
                  <IconC />
                </span>
              </div>
              <div>
                <span
                  onMouseEnter={() => handleMouseEnter("ROR")}
                  onMouseLeave={handleMouseLeave}
                >
                  <RorIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("RUBY")}
                  onMouseLeave={handleMouseLeave}
                >
                  <RubyIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("SQL")}
                  onMouseLeave={handleMouseLeave}
                >
                  <SqlIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("JAVA")}
                  onMouseLeave={handleMouseLeave}
                >
                  <JavaIcon />
                </span>
                <span
                  onMouseEnter={() => handleMouseEnter("TYPE")}
                  onMouseLeave={handleMouseLeave}
                >
                  <TypeIcon />
                </span>
              </div>
            </div>
          </div>
        )
        }

      </div >
      <div className={styles["right-side"]}>
        <div className={styles["title-box"]}>
          {hoveredSkill ? (
            <>
              <h1 className={styles["skill-name"]}>{hoveredSkill.title}</h1>
              <div className={styles["skill-description"]}>
                <StarRating rating={hoveredSkill.rate} />
              </div>
            </>
          ) : (
            <p className={styles["global"]}>
              <em>
                While it can be challenging to assess my proficiency in each
                technology without a clear standard or scale, you'll find on the
                left the languages and tools I've used most extensively. I'm
                also deeply interested in exploring other areas, such as DevOps,
                mobile development, and 3D graphics with ThreeJS. I'm
                continuously working to expand my skills and deepen my
                understanding as a developer.
              </em>
            </p>
          )}
        </div>
      </div>
      {isMobile && <div className={styles.title}>Skills </div>}
    </section >
  );
}

export default Competences;
