import React from "react";

const RubyIcon = () => {
  return (
    <svg
      width="80px"
      height="80px"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.594 35.797 24.89 43.933l17.742-1.204L44 24.84l-5.406 10.958z"
        fill="url(#a)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m42.663 42.717-1.525-10.525-4.154 5.485 5.679 5.04z"
        fill="url(#b)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m42.683 42.717-11.172-.877-6.56 2.07 17.732-1.193z"
        fill="url(#c)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m24.966 43.913 2.791-9.143-6.141 1.313 3.35 7.83z"
        fill="url(#d)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m36.983 37.707-2.568-10.059-7.35 6.89 9.918 3.17z"
        fill="url(#e)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m43.417 27.79-6.947-5.674-1.934 6.254 8.881-.58z"
        fill="url(#f)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m40.169 20.093-4.086 2.258-2.578-2.288 6.664.03z"
        fill="url(#g)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m20 39.15 1.712-3.122-1.385-3.719L20 39.15z"
        fill="url(#h)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m20.235 32.192 1.393 3.951 6.053-1.358 6.91-6.422 1.95-6.195L33.472 20l-5.22 1.954c-1.646 1.53-4.838 4.557-4.953 4.614-.113.058-2.107 3.826-3.063 5.624z"
        fill="#ffffff"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.126 25.093c3.565-3.534 8.161-5.622 9.925-3.843 1.762 1.78-.107 6.104-3.672 9.637-3.565 3.534-8.104 5.737-9.866 3.958-1.764-1.779.048-6.218 3.613-9.752z"
        fill="url(#i)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m24.966 43.91 2.77-9.173 9.196 2.954c-3.325 3.118-7.023 5.754-11.966 6.218z"
        fill="url(#j)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m34.604 28.345 2.36 9.351c2.778-2.92 5.271-6.06 6.492-9.944l-8.852.593z"
        fill="url(#k)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.432 27.8c.945-2.852 1.163-6.942-3.293-7.702l-3.655 2.02 6.948 5.682z"
        fill="url(#l)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 39.109c.13 4.706 3.526 4.776 4.973 4.817l-3.341-7.802L20 39.109z"
        fill="#9E1209"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.617 28.36c2.134 1.311 6.436 3.946 6.523 3.994.136.077 1.855-2.898 2.245-4.58l-8.768.585z"
        fill="url(#m)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m27.732 34.737 3.702 7.142c2.189-1.187 3.903-2.633 5.473-4.183l-9.175-2.959z"
        fill="url(#n)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m21.617 36.134-.524 6.245c.99 1.352 2.35 1.47 3.779 1.364-1.033-2.572-3.097-7.714-3.255-7.61z"
        fill="url(#o)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m36.462 22.133 7.354 1.032c-.393-1.663-1.598-2.736-3.652-3.072l-3.702 2.04z"
        fill="url(#p)"
      />
      <defs>
        <linearGradient
          id="a"
          x1="41.086"
          y1="46.11"
          x2="36.023"
          y2="37.171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FB7655" />
          <stop offset=".41" stop-color="#E42B1E" />
          <stop offset=".99" stop-color="#900" />
          <stop offset="1" stop-color="#900" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="43.608"
          y1="38.601"
          x2="37.083"
          y2="34.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#871101" />
          <stop offset=".99" stop-color="#911209" />
          <stop offset="1" stop-color="#911209" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="38.387"
          y1="46.381"
          x2="31.863"
          y2="42.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#871101" />
          <stop offset=".99" stop-color="#911209" />
          <stop offset="1" stop-color="#911209" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="24.687"
          y1="35.431"
          x2="25.699"
          y2="42.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ffffff" />
          <stop offset=".23" stop-color="#E57252" />
          <stop offset=".46" stop-color="#DE3B20" />
          <stop offset=".99" stop-color="#A60003" />
          <stop offset="1" stop-color="#A60003" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="31.645"
          y1="29.293"
          x2="32.017"
          y2="36.002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ffffff" />
          <stop offset=".23" stop-color="#E4714E" />
          <stop offset=".56" stop-color="#BE1A0D" />
          <stop offset=".99" stop-color="#A80D00" />
          <stop offset="1" stop-color="#A80D00" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="37.819"
          y1="23.091"
          x2="38.935"
          y2="27.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ffffff" />
          <stop offset=".18" stop-color="#E46342" />
          <stop offset=".4" stop-color="#C82410" />
          <stop offset=".99" stop-color="#A80D00" />
          <stop offset="1" stop-color="#A80D00" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="34.412"
          y1="21.398"
          x2="39.22"
          y2="18.994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ffffff" />
          <stop offset=".54" stop-color="#C81F11" />
          <stop offset=".99" stop-color="#BF0905" />
          <stop offset="1" stop-color="#BF0905" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="20.473"
          y1="33.755"
          x2="20.869"
          y2="37.717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ffffff" />
          <stop offset=".31" stop-color="#DE4024" />
          <stop offset=".99" stop-color="#BF190B" />
          <stop offset="1" stop-color="#BF190B" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="17.752"
          y1="38.788"
          x2="36.355"
          y2="19.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BD0012" />
          <stop offset=".07" stop-color="#ffffff" />
          <stop offset=".17" stop-color="#ffffff" />
          <stop offset=".27" stop-color="#C82F1C" />
          <stop offset=".33" stop-color="#820C01" />
          <stop offset=".46" stop-color="#A31601" />
          <stop offset=".72" stop-color="#B31301" />
          <stop offset=".99" stop-color="#E82609" />
          <stop offset="1" stop-color="#E82609" />
        </linearGradient>
        <linearGradient
          id="j"
          x1="32.001"
          y1="40.718"
          x2="26.398"
          y2="39.335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8C0C01" />
          <stop offset=".54" stop-color="#990C00" />
          <stop offset=".99" stop-color="#A80D0E" />
          <stop offset="1" stop-color="#A80D0E" />
        </linearGradient>
        <linearGradient
          id="k"
          x1="41.625"
          y1="33.992"
          x2="36.648"
          y2="29.531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7E110B" />
          <stop offset=".99" stop-color="#9E0C00" />
          <stop offset="1" stop-color="#9E0C00" />
        </linearGradient>
        <linearGradient
          id="l"
          x1="43.454"
          y1="25.807"
          x2="40.975"
          y2="23.156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#79130D" />
          <stop offset=".99" stop-color="#9E120B" />
          <stop offset="1" stop-color="#9E120B" />
        </linearGradient>
        <linearGradient
          id="o"
          x1="23.231"
          y1="43.904"
          x2="21.21"
          y2="37.048"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B2114" />
          <stop offset=".43" stop-color="#9E100A" />
          <stop offset=".99" stop-color="#B3100C" />
          <stop offset="1" stop-color="#B3100C" />
        </linearGradient>
        <linearGradient
          id="p"
          x1="38.732"
          y1="21.187"
          x2="43.262"
          y2="23.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B31000" />
          <stop offset=".44" stop-color="#910F08" />
          <stop offset=".99" stop-color="#791C12" />
          <stop offset="1" stop-color="#791C12" />
        </linearGradient>
        <radialGradient
          id="m"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.423 29.616) scale(6.09995)"
        >
          <stop stop-color="#A80D00" />
          <stop offset=".99" stop-color="#7E0E08" />
          <stop offset="1" stop-color="#7E0E08" />
        </radialGradient>
        <radialGradient
          id="n"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.975 37.655) scale(8.10906)"
        >
          <stop stop-color="#A30C00" />
          <stop offset=".99" stop-color="#800E08" />
          <stop offset="1" stop-color="#800E08" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default RubyIcon;
